<template>
  <img alt="Vue logo" src="./assets/auto.webp">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
img {
	width: 100%;
}
html {
	height: 100vh;
}
html.firework {
	background: url("./assets/giphy.gif");
	background-repeat: no-repeat;
	background-size: cover;
	background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 80vh;
}
</style>
