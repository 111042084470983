<template>
  <div class="hello">
		<div class="timer">
			<div class="item">
				<h1>{{ days }}</h1>
				<span>DAYS</span>
			</div>
			<div class="item">
				<h1>{{ hours }}</h1>
				<span>HOURS</span>
			</div>
			<div class="item">
				<h1>{{ minutes }}</h1>
				<span>MINUTES</span>
			</div>
			<div class="item">
				<h1>{{ seconds }}</h1>
				<span>SECONDS</span>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
		return {
			days: 0,
			hours: 1,
			minutes: 1,
			seconds: 1,
			targetDAteSting: '11/22/2022'
		}
	},
	created() {
		let params = (new URL(document.location)).searchParams;
		let string = params.get('date')
		if (string) {
			this.targetDAteSting = string;
		}
		this.getDate();
	},
	methods: {
		getDate() {
			let date = new Date();
			let targetDate = new Date(this.targetDAteSting);
			let Difference_In_Time = targetDate.getTime() - date.getTime();

			if (Difference_In_Time <= 0) {
				document.getElementsByTagName('html')[0].classList.add('firework')
				this.days = '00';
				this.hours = '00';
				this.minutes = '00';
				this.seconds = '00';
				return
			}

			let days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
			this.days = days < 10 ? '0' + days : days
			let h = (Difference_In_Time / (1000 * 3600 * 24)) - this.days;
			let hours = Math.floor(24 * h);
			this.hours = hours < 10 ? '0' + hours : hours
			let m = (24 * h) - this.hours;
			let minutes = Math.floor(60 * m);
			this.minutes = minutes < 10 ? '0' + minutes : minutes
			let s = (60 * m) - this.minutes;
			let seconds = Math.floor(60 * s);
			this.seconds = seconds < 10 ? '0' + seconds : seconds
			setTimeout(this.getDate, 1000)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.timer {
	display: flex;
	justify-content: center;
}
.item {
	margin: 10px;
}
h1 {
	font-size: 100px;
	line-height: 90px;
	padding: 0;
	margin: 0;
}
@media screen and (max-width: 800px) {
	h1 {
		font-size: 80px;
	}
}
@media screen and (max-width: 500px) {
	h1 {
		font-size: 50px;
		line-height: 45px;
	}
	.item {
		margin: 5px;
	}
	span {
		font-size: 10px;
	}
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.firework h1, .firework span {
	color: white
}
</style>
